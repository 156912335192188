<template>
	<div class="companyprofile">
		<div class="bg">
			<img src="@imgs/gsjjbg.png" width="341px" height="93px" alt="" class="cpzx" />
		</div>
		<div class="companybrief">
			<div class="title">
				<div class="raio"></div>
				<span>公司简介</span>
				<div class="raio"></div>
			</div>
			<div class="content">
				<p>云南康喜药业有限公司创建于2015年的云南康喜药业有限公司，是以药品、中药饮片、保健食品等为主的医药零售企业，公司注册资本300万元，截至2021年12月公司旗下拥有10家直营零售分店。</p>
				<p>多年来，公司始终坚持"质量第一，品质保证，顾客满意"的健康企业理念，秉承"负责，诚实，荣誉"的企业风格，以行业内先进零售药店模式为样板，积极探索，不断努力创新，创立了自己独有的发展策略及经营模式，康喜人在艰辛的创业道路上，历经变迁和磨砺，但康喜人的步履依然坚定，理想依然不灭，重审过去，面向未来，康喜人必将为实现公司效益化和规模化的发展，全力以赴，矢志不渝。</p>
				<p>公司的核心价值观是：健康成长、幸福生活。公司倡导人才是第一生产力，重视人才的培养和发展。</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				proList: [{
						id: 0,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 1,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 2,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 3,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 4,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 5,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 6,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 7,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 8,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 9,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 10,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
					{
						id: 11,
						img: require("@imgs/pricer.png"),
						title: "西洋参"
					},
				],
			};
		},
	};
</script>

<style lang="scss" scoped>
	.companyprofile {

		.companybrief {
			margin-top: 30px;

			.title {
				font-size: 22px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #fe2a1e;
				display: flex;
				justify-content: center;
				align-items: center;

				.raio {
					width: 6px;
					height: 6px;
					background: #fe2a1e;
					border-radius: 50%;
					display: inline-block;
				}

				span {
					margin: 0 7px;
				}
			}

			.content {
				width: 1187px;
				margin: 0 auto;
				margin-bottom: 30px;
				text-align: left;
				font-size: 18px;
				font-family: DengXian;
				color: #666666;
			}
		}
	}
</style>
